<template>
    <b-container>
        <b-card>
            <errors-bag-list/>
            <b-form>
                <b-row class="d-flex justify-content-center">
                    <b-col cols="9">
                        <b-form-group label="Наименование">
                            <b-form-input
                                v-model="form.name"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="d-flex justify-content-center">
                    <b-col cols="6">
                        <b-form-group label="Введите критерий">
                            <b-form-input v-model="newItem.name" @keyup.enter="addItem()"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Действия">
                            <b-button variant="primary" @click="addItem()">
                                <b-icon-plus/>
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row
                    v-show="form.items.length > 0"
                    v-for="(item, index) of form.items"
                    :key="index"
                    class="d-flex justify-content-center"
                >
                    <b-col cols="6">
                        <b-form-group>
                            {{ item.name }}
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group>
                            <b-button
                                variant="danger"
                                @click="destroyItem(index)"
                            >
                                <b-icon-x/>
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="d-flex justify-content-end">
                    <b-col cols="auto">
                        <b-button v-if="form.id" variant="primary" @click="update">Сохранить</b-button>
                        <b-button v-else variant="primary" @click="create">Создать</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-container>
</template>

<script>
import {CHECKLIST_TEMPLATES_ENDPOINT} from '@utils/endpoints';

import ErrorsBagList from '@components/_common/ErrorsBagList';

export default {
    name: 'Form',
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            form: {
                id: this.id,
                name: null,
                items: [],
            },
            newItem: {
                checklist_template_id: null,
                name: null,
            },
        }
    },
    components: {
        ErrorsBagList,
    },
    methods: {
        load() {
            const params = {
                with: ['items'],
            };

            this.$http.get(
                `${CHECKLIST_TEMPLATES_ENDPOINT}/${this.form.id}`,
                {params}
            ).then((response) => this.form = response.data.data);
        },
        create() {
            this.$http.post(
                CHECKLIST_TEMPLATES_ENDPOINT,
                this.form
            ).then(() => {
                this.$router.push({name: 'ChecklistTemplates'});
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.discard();
                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        update() {
            this.$http.put(
                `${CHECKLIST_TEMPLATES_ENDPOINT}/${this.form.id}`,
                this.form
            ).then(() => {
                this.load();
                this.$bvToast.toast('Успешно обновлено', {variant: 'success'});
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.discard();
                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        addItem() {
            if (this.newItem.name) {
                this.newItem.checklist_template_id = this.form.id;
                this.form.items.push(this.newItem);
            }

            this.newItem = {};
        },
        destroyItem(id) {
            this.form.items.splice(id, 1);
        }
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    }
}
</script>
